/* src/Profile.css */
.profile-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h1 {
  font-size: 2.5em;
  color: #333;
}

h2 {
  font-size: 1.8em;
  color: #555;
}

h3 {
  margin-top: 20px;
  font-size: 1.5em;
  color: #777;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 5px 0;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}
